module.exports = (function () {
  const envs = {
    local: {
      // apiUrl: 'http://srv1:9128/api/v1',
      // newApiUrl: 'http://srv1:7651/api/v1',
      apiUrl: 'https://talkmore-api.levelupify.com/api/v1',
      newApiUrl: 'https://tm-api-new.lvlp.no/api/v1',
      // apiUrl: 'https://talkmore-api.levelupify.com/api/v1',
      // newApiUrl: 'https://cluster.leveluphosting.no/api/v1',
    },
    dev: {
      apiUrl: 'https://talkmore-api.levelupify.fun/api/v1', // .fun doesn't seem to exist anymore
      newApiUrl: 'https://api-talkmore.levelupify.fun/api/v1', // .fun doesn't seem to exist anymore
    },
    staging: {
      // apiUrl: 'https://talkmore-api.levelupify.com/api/v1',
      // newApiUrl: 'https://api-talkmore-staging.levelupify.com/api/v1',
      apiUrl: 'https://talkmore-api.levelupify.com/api/v1',
      newApiUrl: 'https://api-talkmore-staging.levelupify.com/api/v1',
    },
    prod: {
      apiUrl: 'https://talkmore-api.levelupify.com/api/v1',
      // newApiUrl: 'https://api-talkmore.levelupify.com/api/v1',
      newApiUrl: 'https://tm-api-new.lvlp.no/api/v1',
      // newApiUrl: 'https://tm-api-new.lvlp.no/api/v1',
    },
  }

  const isLocal = () => {
    return /^localhost/.test(window.location.hostname) || /^srv1/.test(window.location.hostname);
  }

  const isStaging = () => {
    return false
    // return /^feltsalg\.levelupify\.com/.test(window.location.hostname);
  }

  const isDev = () => {
    return /\.levelupify\.fun/.test(window.location.hostname);
  }

  const isProd = () => {
    return !(isLocal() || isDev() || isStaging())
  }

  const getProperty = (name) => {
    let key
    if (isLocal()) {
      key = 'local'
    } else if (isDev()) {
      key = 'dev'
    } else if (isStaging()) {
      key = 'staging'
    } else {
      key = 'prod'
    }
    return envs[key][name]
  };

  let isDebug = false
  let showingDebugInfo = false
  let debugSize = 'normal'
  try {
    isDebug = window.localStorage.getItem('is_debug')
    showingDebugInfo = window.localStorage.getItem('showing_debug_info')
    debugSize = window.localStorage.getItem('debug_size')
  } catch (e) {
    console.log(e)
  }

  return {
    isLocal: isLocal(),
    isDev: isDev(),
    isStaging: isStaging(),
    isProd: isProd(),
    apiUrl: getProperty('apiUrl'),
    newApiUrl: getProperty('newApiUrl'),
    isDebug: isDebug,
    showingDebugInfo: showingDebugInfo,
    debugSize: debugSize,
    chatDepartmentIds: ['70fa4a9a-fcb2-4bc8-a94a-6e9a064716aa'],
    sharedDataOwnerPlan: 'b28457b6-e1a3-4d1f-8e6b-ba749b21a77d',
    sharedDataMemberPlan: '49de9802-df04-4482-8f11-5e93f7869b58',
    extraAbroadId: '3a19e855-972e-41de-94a0-3041dd1db381',
    digitalSafetyId: '4d03eef1-5404-4a94-a65a-48a49a149ac4',
    buyoutDiscountId: 'd2cfaadf-a94a-48ca-8503-295b292e997b',
    portDiscountId: '324ff819-5e27-423b-8fb7-7e5ece382f77',
    webProtectionId: '171880a3-3eea-41c5-8595-41ca7c5c6a6d',
    fullControlId: '91a200fc-77cf-4548-947a-ded3c2fd67ba',
    twinSimId: 'a4e4f53d-d554-4efa-a23a-f85728af51cb',
    dataCardId: '27ca6935-3544-4041-b819-12b003fa7911',
    extra10GbId: 'bb9ac025-9cc2-4f32-874a-ed4ff3b0fef5',
    bulkInvoiceDiscountId: '168b2c0a-9837-449a-9451-e4706322ebe5',
    campaignsAsExtraServices: [
      ['Datakort', 'ab812c02-c3bc-4192-b246-1ec03f4cb40c'],
      ['TvillingSIM', '1b687908-3ea0-4141-a8df-ac3269696b93'],
    ],
    ukraineDepartmentId: '16139c3e-1e02-2473-4a3d-3eff542c9212',
    ukraineSubscriptionId: 'c92f0456-5bea-4605-ab92-8c22ea7aaa8a',
    ukraineCampaigns: [
      '53aee3ac-66a9-4347-aad0-a55ef2c878fd',
      'd157b991-030f-4f9f-adf5-2e49252a66de',
    ],
    blackFridayCampaignId: 'b7951959-2ff0-40cc-9ef5-b11a9266ac97',
    outletDiscountCampaignId: 'd2cfaadf-a94a-48ca-8503-295b292e997b',
    u30CampaignId: '9b9ac34b-1867-43aa-8a78-757cca77bed9',
    unlimitedSubscriptionIds: [
      'b5321b89-0a0b-4afa-8214-21d34487a632',
      '22caefe6-062b-4bcb-ae3b-5ebf5f8f826d',
      '49dd07b8-0f78-489c-80a6-d8ad12bfa172'
    ],
  }
})()