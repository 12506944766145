
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import validate from 'validate.js'
import DatePicker from 'react-datepicker'

import config from '../../../../../config'
import { validateFamilyPackages } from '../../../../../lib/family_validation'

import { setFormData } from '../../../../../actions/formData'
import formData from '../../../../../reducers/formData'
import { getFamilySubscriptionTotalPrice } from '../../../../../lib/prices'
import SpecialCampaign from '../../../../SpecialCampaign'

class FamilyStep1Component extends Component {
  constructor(props) {
    super(props)
    const { formData } = props

    this.state = {}
  }

  allowNext = () => {
    const { formData } = this.props

    return validateFamilyPackages(formData.family)
  }

  getTypeCount = (type) => {
    const { formData } = this.props
    const family = formData.family || {}
    const counts = family.counts || {}
    return counts[type] || 0
  }

  getTotalCount = () => {
    const { formData } = this.props
    const family = formData.family || {}
    const counts = family.counts || {}
    return (counts.adult || 0) + (counts.child || 0)
  }

  nextStep = () => {
    const { formData, dispatch } = this.props
    dispatch(setFormData({ familyStep: 'owner' }))
  }

  close = () => {
    const { formData, dispatch, parent } = this.props
    dispatch(setFormData({ newFamilyPhoneSession: false }))
    parent.setState({ showAddFamilyPhoneModal: false })
  }

  changeCount = (type, action) => {
    const { formData, dispatch } = this.props

    const members = [...formData.family.members]
    let count = formData.family.counts[type]

    if (action === 'add') {
      count++
      members.push({
        type: type,
        set: false,
        id: Math.random().toString(),
        subscription: true,
      })
    } else {
      const unsetIdx = members.findIndex((m) => m.type === type && !m.owner && !m.set)
      const idx = unsetIdx !== -1 ? unsetIdx : members.findIndex((m) => m.type === type && !m.owner)

      if (idx !== -1) {
        count--
        members.splice(idx, 1)
      }
    }

    dispatch(setFormData({
      family: {
        ...formData.family,
        counts: {
          ...formData.family.counts,
          [type]: count,
        },
        members,
      }
    }))
  }

  pricePerPerson = () => {
    const { formData, appData } = this.props
    const pkg = formData.family.selectedPackageId ? appData.familyDataPackages.filter(p => p.id === formData.family.selectedPackageId)[0] : null

    return (pkg ? parseInt(getFamilySubscriptionTotalPrice(formData.family, pkg) / this.getTotalCount()) : 0)
  }

  render() {
    const { item, showAddOnModal, choices, extraServices } = this.state
    const { user, appData, formData, dispatch, layout, subscriptions } = this.props
    const { departmentId } = layout.data

    return (
      <div>
        <h1>Hvor mange skal ha abonnement?</h1>
        <p>Minimum to personer.</p>
        <div className="familyMemberCounts">
          {/* 
          <div className="childrenCount counts">
            <h2>Barn</h2>
            <p className="ageDescription">under 18</p>
            <div className="countSelectors">
              <div className={`minus${(this.getTypeCount('child') <= 0 ? ' disabled' : '')}`} onClick={() => this.changeCount('child', 'subtract')}>-</div>
              <div className="count">{this.getTypeCount('child')}</div>
              <div className="plus" onClick={() => this.changeCount('child', 'add')}>+</div>
            </div>
          </div>
          */}
          <div className="adultCount counts">
            <h2>Antall medlemmer</h2>
            {/* <p className="ageDescription">over 18</p> */}
            <div className="countSelectors">
              <div className={`minus${(this.getTypeCount('adult') <= 0 ? ' disabled' : '')}`} onClick={() => this.changeCount('adult', 'subtract')}>-</div>
              <div className="count">{this.getTypeCount('adult')}</div>
              <div className="plus" onClick={() => this.changeCount('adult', 'add')}>+</div>
            </div>
            {(true || config.chatDepartmentIds.includes(departmentId)) &&
              <div className="includeExtraData" style={{ marginTop: '4rem' }}>
                <div className="input_wrapper">
                  <input type="checkbox" id="include-extra-data" onChange={ev => dispatch(setFormData({ family: { ...formData.family, includeExtraData: ev.target.checked } }))} />
                  <label htmlFor="include-extra-data"><span>Inkluder ekstra data?</span></label>
                </div>
              </div>
            }
          </div>
        </div>
        <h1>Velg felles data</h1>
        <div className="dataPriceBoxes">
          {_.sortBy(appData.familyDataPackages, (p) => parseInt(p.bot_key.replace(/[^\d]+/g, ''))).map((pkg) => {
            const classNames = formData.family.selectedPackageId === pkg.id ? "dataPriceBox selected" : "dataPriceBox"
            const packagePrice = getFamilySubscriptionTotalPrice(formData.family, pkg)
            const gbStr = pkg.name === 'Familiedata Ubegrenset'
              ? 'Ubegrenset'
              : !config.chatDepartmentIds.includes(departmentId) || (config.chatDepartmentIds.includes(departmentId) && formData.family.includeExtraData) // config.chatDepartmentIds.includes(departmentId)
                ? pkg.name.replace(/^[a-zA-Z -]+/, '')
                : `${parseInt(pkg.bot_key.replace(/[^0-9]/g, ''))} GB`
            return (
              <div key={`pkg-${pkg.id}`} className={classNames} onClick={() => dispatch(setFormData({ family: { ...formData.family, selectedPackageId: pkg.id } }))}>
                <div className="dataAmount">{gbStr}</div>
                <div className="dataPrice">{packagePrice} kr</div>
                <div className="dataDescription">/mnd<br />{this.getTotalCount()} pers.</div>
              </div>
            );
          })}
        </div>
        {formData.family.selectedPackageId && this.getTotalCount() >= 2 &&
          <p>
            Pris per person: kr {this.pricePerPerson()}/mnd
          </p>
        }
        {false && formData.family && formData.family.selectedPackageId &&
          <SpecialCampaign />
        }
        <div className="buttons">
          <button className="cancel" onClick={this.close}>Lukk</button>
          <button className="continue" disabled={!this.allowNext()} onClick={this.nextStep}>Fortsett</button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    formData: state.formData,
    appData: state.appData,
    layout: state.layout,
  }
}

const FamilyStep1 = connect(
  mapStateToProps,
  mapDispatchToProps
)(FamilyStep1Component)

export default FamilyStep1
